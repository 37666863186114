





















































































































import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {Board, WorkspaceMember} from '@/typescript/types';
import UserAvatar from "@/components/user/UserAvatar.vue";
// @ts-ignore
import Encryption from '@/encryption.ts';
//
// import {
//   BoardNotificationCreatedSubscription,
//   BoardNotificationsQuery
// } from "@/graphql/BoardNotification";
import {isFuture, isPast} from "date-fns";
import Paywall from "@/components/subscription/Paywall.vue";
// import {
//   WorkspaceNotificationCreatedSubscription,
//   WorkspaceNotificationsForBoardQuery
// } from "@/graphql/WorkspaceNotification";


@Component({
  components: {
    WorkspaceBoardItem,
    UserAvatar
  },
  apollo: {
    // getWorkspaceNotificationsForBoard: {
    //   query: WorkspaceNotificationsForBoardQuery,
    //   result(result) {
    //     Vue.prototype.$consoleLog(result)
    //     // if(result.data) {
    //     //   //let data = result.data.getBoardNotifications.data;
    //     //   //this.$store.commit('set_board_notification', data)
    //     // }
    //
    //     return result.data.getWorkspaceNotificationsForBoard;
    //   },
    //   subscribeToMore: {
    //     document: WorkspaceNotificationCreatedSubscription,
    //     variables() {
    //       return {
    //         workspace_id: this.$store.state.workspace.id,
    //         board_id: this.board.id
    //       }
    //     },
    //     updateQuery(previous, {subscriptionData}) {
    //       let boardNotification = subscriptionData.data.workspaceNotificationCreated;
    //       let message = subscriptionData.data.workspaceNotificationCreated.data.message;
    //       this.requestNotificationAccess();
    //       Vue.prototype.$consoleLog(previous.getWorkspaceNotificationsForBoard.__typename)
    //       //
    //       if (this.board.id !== this.activeBoardPid) {
    //         message = Encryption.decrypt(message);
    //         this.triggerNotification(message)
    //       }
    //
    //       //this.$store.commit('add_board_notification', boardNotification)
    //
    //       return {
    //         getWorkspaceNotificationsForBoard: {
    //           data: [boardNotification,...previous.getWorkspaceNotificationsForBoard.data],
    //           paginatorInfo: previous.getWorkspaceNotificationsForBoard.paginatorInfo,
    //           __typename: previous.getWorkspaceNotificationsForBoard.__typename,
    //         },
    //       };
    //     },
    //   },
    //   variables() {
    //     return {
    //       id: this.board.id,
    //     };
    //   },
    // }
  }
})

export default class WorkspaceBoardItem extends Vue {
  @Prop({default: undefined, required: true})
  board!: Board;
  //getWorkspaceNotificationsForBoard: WorkspaceNotificationPaginator | null = null;
  timer: any ;
  setInterval = 5000;
  isChatOpened: boolean = false;
  showFaviconNotification: boolean = false;

  @Prop({default: false, required: false})
  blockedByPlanAccess!: boolean;

  get activeBoardPid() {
    return this.$store.state.active_board_pid;
  }

  get allBoardsLiveMembers() {
    return this.$store.state.me.boardLiveMembers;
  }

  get chatRouteActive() {
    return this.board.pid === this.activeBoardPid && this.$route.name === 'chat';
  }

  openBoard(boardPid: string) {
    if(!this.blockedByPlanAccess) {
      if(boardPid !== this.$store.state.boardPid) {
        this.$router.push({ name: 'room', params: { board_pid: boardPid} });
      }
      this.$emit('route-changed', true);
    } else {
      this.openPaywall();
    }
  }

  openBoardWithChat(boardPid: string) {
    if(!this.blockedByPlanAccess) {
      if(this.$route.name !== 'chat' || boardPid !== this.$store.state.board.pid) {
        this.$router.push({ name: 'chat', params: { board_pid: boardPid} });
      }
    } else {
      this.openPaywall();
    }
  }

  playSound() {
    let audio = new Audio(require('@/assets/audio/chat-message.mp3'));
    audio.volume = 0.2;
    audio.play();
  }

  get workspaceMembers(){
    return this.$store.state.workspace.members;
  }

  get whoViewingBoard(){
    let members: Array<any> = [];
    let currentBoard = this.$store.state.me.boardLiveMembers.filter((data: any) => data.boardId === this.board.pid);
    if(currentBoard[0].members.length > 0){
      currentBoard[0].members.forEach((id: string) => {
        members.push(this.workspaceMembers.find((user: WorkspaceMember) => id === user.user.id))
      })
    }

    return members;
  }

  get ongoingActivityCount() {
    if(this.board.ongoingObjectives && this.board.ongoingObjectives.length > 0) {
      let count:number = 0;
      this.board.ongoingObjectives!.forEach((obj: any) => {
        if (obj.status != 'completed' && obj.start_date != null && isPast(new Date(obj.start_date)) && (obj.due_date == null || isFuture(new Date(obj.due_date))) ){
          count++;
        }
      })
      return count;
    }
    return null;
  }

  get chatNotification() {
    return this.$store.state.chat_notification.includes(this.board.id)
  }

  openPaywall() {
    this.$buefy.modal.open({
      component: Paywall,
      // @ts-ignore
      customClass: 'split-modal',
      parent: this,
    });
  }

  requestNotificationAccess() {
    if (Notification.permission !== "denied") {
      Notification.requestPermission()
    }
  }

  triggerNotification(message: string) {
    if (!("Notification" in window)) {
      return
    }

    if (Notification.permission !== "denied" && Notification.permission != 'granted') {
      this.$events.fire('open-browser-notofication-permission')
    }

    if (Notification.permission === "granted") {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        new Notification(message);
        this.playSound();
      }, this.setInterval);
    }
    document.title = "You have unread messages...";
  }

  switchFavicon(isNotifyFavicon: boolean) {
    const faviconElement = document.getElementById('favicon') as HTMLLinkElement;
    if (!faviconElement) {
      // Favicon element with id "favicon" not found
      return;
    }

    if (!isNotifyFavicon) {
      // Switch to the default favicon with a unique query string
      faviconElement.href = window.location.origin + '/favicon.ico?v=' + new Date().getTime();
    } else {
      // Switch to the "notify" favicon with a unique query string
      faviconElement.href = window.location.origin + '/favicon-notify.ico?v=' + new Date().getTime();
      
    }
  }

  @Watch('chatNotification', {immediate: true})
  onChange() {
    if(this.chatNotification) {
      this.switchFavicon(true);
    } else {
      this.switchFavicon(false);
    }
  }



  mounted() {
    this.$events.listen('board-notification', eventData => {
      if(eventData.data.board && eventData.data.board.id == this.board.id && eventData.data.read == false && this.isChatOpened == false) {
        //Enable notification
        this.$store.commit('add_chat_notification', eventData.data.board.id);
        this.$store.state.set
        if(eventData.isSubs && eventData.data.board.id != this.activeBoardPid) {
          let message = Encryption.decrypt(eventData.data.data.message);
          this.triggerNotification(message);
        }
      }
    })

    this.$events.listen('removeNotification', (boardId) => {
      if(boardId == this.board.id) {
        //Remove notification
        this.$store.commit('remove_chat_notification', boardId);

      }

    })

    this.$events.listen('chat-opened', (data) => {
      if(data.board_id == this.board.id) {
        this.isChatOpened = data.opened;
      }
    })

    this.$events.listen('chat-closed', (_) => {
      this.isChatOpened = false;
    })
  }


}
